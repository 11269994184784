<template>
  <v-dialog v-model="showModal" width="70%" persistent>
    <v-card class="video_call">
      <div id="meet"></div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      audio: null,
      current_sublocation: null,
      api: null,
    };
  },
  props: ["showModal", "answeringCall", "sublocationId"],
  mounted() {
    if(this.$route.query.id && this.$route.query.id.includes("_")){
      this.current_sublocation = this.sublocationId ? this.sublocationId : null;
      console.log('---> Sublocation id Param!!!', this.current_sublocation)
      console.log('---> AnsweringCall Param!!!', this.answeringCall)
      if(this.answeringCall !== null && this.answeringCall === true && this.current_sublocation !== null){
        this.getToken()
      }
    }else{
      this.current_sublocation = this.$route.query.id ? this.$route.query.id : null;
    }    
    this.connection_id = this.buildDeviceId();    
  },
  methods: {
    getToken() {
      console.log("get token");
      try {
        this.$http
          .get("digital_guard/generate_jwt", {
            params: {
              sublocation_id: this.current_sublocation,
            },
            headers: {
              "X-Device-ID": this.getDeviceId(),
              Authorization: process.env.VUE_APP_JITSI_TOKEN_AUTHORIZATION,
            },
          })
          .then(function(response) {
            console.log("-------->RESPONSE TOKEN", response);
            var token = response.body.data;
            setTimeout(() => this.launchVideoCall(token), 1000);            
          });
      } catch (e) {
        alert(e.message);
      }
    },
    launchVideoCall(token) {
      const room = `room_virtual_guard_${this.current_sublocation}`;
      this.$cable.perform({
        channel: "VirtualGuardChannel",
        action: "send_message",
        data: {
          room: room,
          message: "answerCall",
          answer_call: true,
          sublocation_id: this.current_sublocation,
          connection_id: this.connection_id,
        },
      });
      console.log("--------> launchVideoCall");
      const domain = process.env.VUE_APP_JITSI_URL;
      const options = {
        roomName: `room_virtual_guard_${this.current_sublocation}`,
        width: "100%",
        height: "100%",
        lang: "es",
        parentNode: document.querySelector("#meet"),
        userInfo: {
          email: "user@fusepong.com",
          displayName: "Usuario",
          role: "user",
        },
        configOverwrite: {
          prejoinPageEnabled: false,
          disableDeepLinking: true
        },
        interfaceConfigOverwrite: {
          TOOLBAR_ALWAYS_VISIBLE: true
        },
        jwt: token,
      };

      this.api = new window.JitsiMeetExternalAPI(domain, options);

      this.api.addListener("readyToClose", () => {
        console.log("---------------> readyToClose");        
        const room = `room_virtual_guard_${this.current_sublocation}`;
        this.$cable.perform({
          channel: "VirtualGuardChannel",
          action: "send_message",
          data: {
            room: room,
            message: "notifyCallEnded",
            notify_call_ended: true,
            sublocation_id: this.current_sublocation,
            connection_id: this.connection_id,
          },
        });
        this.api.dispose();
        this.$emit("close-video-call-modal");
        this.showModal = false;
      });
    }
  },
  watch: {
    showModal(newVal) {
      console.log("---> showModal changed:", newVal);
      if (newVal) {
        this.getToken();
      } else {
        this.api.dispose();
        console.log("closed");
      }
    },
  },
  computed: {
  }
};
</script>

<style scoped></style>
