<template>
  <article class="form_modal__fair access_requirement">
    <section
      class="form_modal_fair__container_video fair_invitation fair_invitation_right"
    >
      <section class="access_requirement__company_logo">
        <div class="access_requirement__company_logo--img">
          <img
            src="https://fusepongsolutions.s3.us-west-2.amazonaws.com/Logofuseaccess.png"
            alt=""
          />
        </div>
      </section>
      <section class="access_requirement__virtual_guard">
        <div class="access_requirement__virtual_guard--img">
          <virtual-guard-logo></virtual-guard-logo>
        </div>
      </section>
      <div class="fair_invitation__content">
        <div class="fair_invitation__content--right">
          <h3 v-if="calling">Llamando ...</h3>
          <!-- <h3 v-else-if="on_call">Linea ocupada</h3> -->
          <h3 v-else-if="answering_call === null">Comunícate con el vigilante virtual</h3>

          <div class="call_buttons">
            <img
              v-if="!calling && answering_call === null"
              src="@/assets/img/call.png"
              class="call_buttons__button"
              @click="call()"
            />
            <img
              v-if="calling && answering_call === null"
              src="@/assets/img/hang.png"
              class="call_buttons__button"
              @click="hangUp()"
            />
          </div>

          <h5 class="call_message" v-if="on_call && answering_call === null">
            No es posible realizar la comunicación en este momento, deja tu
            información en el módulo de contáctanos para comunicarnos
            directamente.
          </h5>
        </div>
      </div>
    </section>

    <video-call-modal
      :show-modal="show_video_call_modal"
      :answering-call="answering_call"
      :sublocation-id="current_sublocation"
      @close-video-call-modal="restoreModals"
    >
    </video-call-modal>
    
  </article>
</template>
<script>
import VirtualGuardLogo from "@/components/shared/VirtualGuardLogo.vue";
import VideoCallModal from "@/components/modals/VideoCallModal.vue";
export default {
  data() {
    return {
      current_sublocation: null,
      connection_id: "",
      show_video_call_modal: false,
      calling: false,
      audio: null,
      timerInterval: "",
      on_call: false,
      sublocation_info: [],
      answering_call: null,
    };
  },
  created() {    
    console.log("getDeviceId()", this.buildDeviceId());
    this.connection_id = this.buildDeviceId();
    if (this.$route.query.id && this.$route.query.id.includes("_")){
      var params = this.$route.query.id.split("_")
      this.current_sublocation = params[0] ? params[0] : null;
      console.log('-----> Current sublocation Param:', this.current_sublocation)
      this.answering_call = params[1] ? true : null;
      console.log("-----> Answering Call Param:", this.answering_call)      
    }else{      
      this.current_sublocation = this.$route.query.id ? this.$route.query.id : null;
      console.log('-----> Current sublocation Param:', this.current_sublocation)
      this.answering_call = null;
    }
    if(this.current_sublocation !== null)
      this.subscribeVirtualGuardChannel(this.current_sublocation);

    if(this.answering_call !== null && this.answering_call === true)
      this.answerCallFromLink()

    this.sublocationInfo();
  },
  mounted() {
  },
  components: {
    "virtual-guard-logo": VirtualGuardLogo,
    "video-call-modal": VideoCallModal,
  },
  computed: {},
  channels: {
    VirtualGuardChannel: {
      connected() {
        console.log("subscribeVirtualGuardChannel");
      },
      received(data) {
        const connectionId = data.data.connection_id;

        // Verifica si el mensaje proviene de la misma conexión (emisor)
        if (connectionId !== this.connection_id) {
          if (data.data.answer_call == true) {
            this.answerCall();
          } else if (data.data.on_call == true) {
            this.onCall();
          } else if (data.data.calling == false) {
            console.log("----------> hag up call");
            this.calling = false;
            this.on_call = true;
            this.audio.pause();
            clearInterval(this.timerInterval);
          } else if (data.data.notify_call_ended == true) {
            console.log("-----------> notify call ended");
            this.restoreModals();
          }
        }
      },
      disconnected() {
        console.log("Disconnected to  channel!");
      },
    },
  },
  methods: {
    sublocationInfo() {
      var self = this;
      try {
        this.$http
          .get("digital_guard/sublocation_info", {
            params: {
              sublocation_id: this.current_sublocation,
            },
            headers: {
              "X-Device-ID": this.getDeviceId(),
            },
          })
          .then(function(response) {
            console.log("-------->RESPONSE LOCATION INFO", response);
            self.sublocation_info = response.body.data;
          });
      } catch (e) {
        alert(e.message);
      }
    },
    answerCall() {
      clearInterval(this.timerInterval);
      this.calling = false;
      this.audio.pause();
      this.show_video_call_modal = true;
    },
    answerCallFromLink() {
      console.log("----------> answer call from whatsapp link");
      this.show_video_call_modal = true;
    },
    onCall() {
      console.log("on call method------>");
      // clearInterval(this.timerInterval)
      // this.audio.pause();
      // this.calling = false
      // this.on_call= true
      // const audioLostCall = new Audio(require('@/assets/audio/lostCall.mp3'));
      // audioLostCall.play();

      // setTimeout(() => {
      //   this.on_call=false
      //   audioLostCall.pause();
      // }, 10000);
    },
    restoreModals() {
      this.show_video_call_modal = false;
      this.calling = false;
      this.answering_call = false
      setTimeout(() => this.unSubscribeVirtualGuardChannel(), 2000); // Desconectarse del canal después de dos segundos
    },
    call() {
      console.log("---------> calling");
      const room = `room_virtual_guard_${this.current_sublocation}`;

      this.on_call = false;
      this.calling = true;
      this.audio = new Audio(require("@/assets/audio/calling.mp3"));
      this.showModalActive = true;
      this.audio.play();

      this.$cable.perform({
        channel: "VirtualGuardChannel",
        action: "send_message",
        data: {
          room: room,
          message: "call",
          calling: true,
          sublocation_info: this.sublocation_info,
          sublocation_id: this.current_sublocation,
          connection_id: this.connection_id,
        },
      });

      let timerSeconds = 0;
      this.timerInterval = setInterval(() => {
        timerSeconds++;
        console.log("-------> timerSeconds", timerSeconds);

        if (timerSeconds === 45) {
          this.on_call = true;
          this.hangUp();
          clearInterval(this.timerInterval); // Detener el intervalo después de 45 segundos
        }
      }, 1000);
    },
    hangUp() {
      console.log("--------> hang up call");

      this.calling = false;
      this.audio.pause();
      clearInterval(this.timerInterval);

      const room = `room_virtual_guard_${this.current_sublocation}`;
      this.$cable.perform({
        channel: "VirtualGuardChannel",
        action: "send_message",
        data: {
          room: room,
          message: "hangUp",
          calling: false,
          sublocation_id: this.current_sublocation,
          connection_id: this.connection_id,
        },
      });
    },
    subscribeVirtualGuardChannel(sublocation_id) {
      this.unsubscribeVirtualGuardChannel();
      if(sublocation_id !== null){
        this.$cable.subscribe({
          channel: "VirtualGuardChannel",
          room: `room_virtual_guard_${sublocation_id}`,
          sublocation_id: sublocation_id,
        });
      }      
    },
    unsubscribeVirtualGuardChannel() {
      this.$cable.unsubscribe("VirtualGuardChannel");
    },
  },
};
</script>

<style scoped></style>
